import React, { useState, useRef } from 'react';
import Loader from 'components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { signInUserApi } from './services/authentication';
import { useHistory } from 'react-router-dom';
import uttarakhanadLogo from '../assets/img/logo/uttarakhanda.png';
import { Footer2ButtonAuth } from '../registration/Components/Footers';
import SFALogo from '../assets/img/SFALogo.svg';
import { useRedirectIfLoggedIn } from 'hooks/useRedirectIfLoggedIn';
import Analytics from '../utils/analytics';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object({
  username: Yup.string().email('Invalid email').required('Kindly Enter a Valid Email ID'),
  password: Yup.string().required('Kindly Enter Your Password'),
});

export default function SignIn() {
  const history = useHistory();
  const { refetch, isLoading } = useRedirectIfLoggedIn();
  const [error, setError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const currentData = useRef(null);
  const signInMutation = useMutation((data) => signInUserApi(data), {
    onSuccess: (data) => {
      if (Analytics)
        Analytics.track('Login', {
          full_name: data?.full_name,
          email: currentData?.username,
        });
      refetch();
    },
    onError: async (err) => {
      setError(err);
      console.debug('Error - ', err);
    },
  });

  const handleSignIn = () =>
    async function (values, { setSubmitting }) {
      setSubmitting(true);
      currentData.current = values;
      await signInMutation.mutateAsync(values);
      setSubmitting(false);
    };

  return isLoading ? (
    <div className="h-screen d-flex align-middle justify-center bg-red">
      <Loader color="purple-400" />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSignIn()}
    >
      {({ isSubmitting }) => (
        <div className="flex flex-row w-screen h-screen">
          <div
            id="blue-bg"
            className="hidden h-screen p-15 lg:flex lg:w-1/2 flex justify-center items-center flex-col text-left font-roboto font-bold"
          >
            <div className="">
              <img src={uttarakhanadLogo} className="h-96" alt={'sfa logo'} />
            </div>
            {/* <div className="mx-20 py-6 mr-8 mb-0 right-tagline font-bold font-poppins">
            Organize,
            <br />
            Play & <br />
            Track The
            <br />
            Easy Way
          </div> */}
            {/* <div className="middle-line bg-opacity-50 w-16 ml-20 mb-10 mt-0  h-1 bg-white" />
          <p className="px-20 py-0 right-info text-left text-white">
            Organize tournaments, manage teams, score <br />
            matches & keep track of your performance in <br />
            every match you play. It's Free!
          </p> */}
          </div>
          <div id="blue-bg" className="w-full lg:w-1/2 md:bg-white md:py-0">
            <img
              src={SFALogo}
              alt="Sfa Play Mobile Logo"
              className="block md:hidden h-12 p-2"
              onClick={() => {
                history.push('/');
              }}
            />
            <div className="py-8 bg-white px-5 md:px-32 lg:px-20 xl:px-32  h-full flex flex-col md:justify-center text-left">
              <div className="action text-xl md:text-3.5xl mb-1 md:mb-0">Sign In</div>
              <div className="pb-8 md:pb-10 info">
                {/* Enter the world of sports by <Link to="/signup/">creating an account</Link> with us! */}
              </div>
              <Form id="signIn-form">
                <label className="font-semibold text-sm text-gray-650 pb-2 md:pb-1 block">
                  Email
                </label>
                <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                  <div className="flex justify-center items-center pl-3 w-15">
                    <FontAwesomeIcon icon={faEnvelope} className="text-gray-450 " />
                  </div>
                  <Field
                    data-cy="signin"
                    type="text"
                    name="username"
                    placeholder="someone@domain.com"
                    className="bg-red flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                  />
                </div>
                <ErrorMessage name="username" component="div" className="flex-1 text-red-400" />
                <label className="font-semibold text-sm text-gray-650 mt-4  pb-2 md:pb-1 block">
                  Password
                </label>
                <div className="flex flex-wrap w-full relative bg-white items-center border-2 rounded-lg">
                  <div className="flex justify-center items-center pl-3 w-15">
                    <FontAwesomeIcon icon={faLock} className="text-gray-450 " />
                  </div>
                  <Field
                    data-cy="password"
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    placeholder="********"
                    className="flex-shrink flex-grow leading-normal w-px flex-1 border-0 p-2 border-grey-400 rounded rounded-l-none px-3 self-center relative font-roboto outline-none"
                  />
                  <div className="flex justify-center items-center pr-3 w-15 ">
                    {passwordVisible ? (
                      <div
                        className="hover:bg-gray-100 px-2 py-1 rounded-full"
                        onClick={() => setPasswordVisible(false)}
                      >
                        <FontAwesomeIcon icon={faEyeSlash} className=" text-gray-450 " />
                      </div>
                    ) : (
                      <div
                        className="hover:bg-gray-100 px-2 py-1 rounded-full"
                        onClick={() => setPasswordVisible(true)}
                      >
                        <FontAwesomeIcon icon={faEye} className=" text-gray-450 " />
                      </div>
                    )}
                  </div>
                </div>
                <ErrorMessage name="password" component="div" className="flex-1 text-red-400" />
                {error !== null ? <div className="flex-1 text-red-400">{error}</div> : null}
                <div className="w-full flex justify-end text-left mt-5">
                  <div
                    className="cursor-pointer font-bold text-gray-600 hover:bg-gray-100 px-4 py-3 rounded-md"
                    onClick={() => {
                      history.push('/forgot-password');
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className="flex center flex-row-reverse md:flex-row 2xl:flex-row justify-center w-full">
                  <button
                    data-cy="submit"
                    type="submit"
                    className="hidden md:inline-block bg-gray-750 hover:bg-blue-800 font-semibold w-1/2 ml-1 rounded-lg my-8 px-10 py-2 text-white"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </button>
                  <button
                    className="cursor-pointer hidden text-center md:inline-block reg-btn-outline-dark mr-1 w-1/2 hover:bg-gray-250 font-semibold rounded-lg my-8 px-10 py-2 text-gray-dark"
                    onClick={() => {
                      history.push('/signup/');
                    }}
                    disabled={isSubmitting}
                  >
                    Create Account
                  </button>
                </div>
              </Form>
              <p className="text-gray-700 hidden">
                <a href="#">Privacy Policy</a> and <a href="#">Terms & Conditions</a> apply
              </p>
            </div>
          </div>
          <Footer2ButtonAuth
            // nextButtonText={cartSummary.data ? `Pay ₹ ${cartSummary.data?.to_be_paid}` : 'Loading...'}
            onCreate={() => {
              history.push(`/signup/`);
            }}
            isSubmitting={isSubmitting}
            // isLoading={isPaymentLoading}
          />
        </div>
      )}
    </Formik>
  );
}
